
import firebase from 'firebase'
import 'firebase/auth'
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDHfHafMvt_xWU4wLL0cokJ3rsjAZoG9Jc",
  authDomain: "store-link-49fa1.firebaseapp.com",
  databaseURL: "https://store-link-49fa1.firebaseio.com",
  projectId: "store-link-49fa1",
  storageBucket: "store-link-49fa1.appspot.com",
  messagingSenderId: "901555007426",
  appId: "1:901555007426:web:d88e7505b98c1380"
}

firebase.initializeApp(config)
