

import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '/report',
      component: () => import('@/layouts/full-page/FullPageWithStoreLink.vue'),
      children: [
        {
          path: '/report',
          name: 'report',
          component: () => import('@/views/pages/Report.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/chromecheck',
          name: 'chromecheck',
          component: () => import('@/views/pages/ChromeCheck.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/scanprint',
          name: 'scanprint',
          component: () => import('@/views/pages/ScanPrint.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },

        {
          path: '/recruite',
          name: 'recruite',
          component: () => import('@/views/pages/Recruite.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/completeregister',
          name: 'completeregister',
          component: () => import('@/views/pages/CompleteRegister.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        }
      ],
    },

    {
      // =============================================================================
      // FULL PAGE LAYOUTS
      // =============================================================================
      //{
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/',
          redirect: '/pages/home'
        },
        {
          path: '/pages/home',
          name: 'home',
          component: () => import('@/views/pages/NewHome.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/dashboard',
          name: 'dashboard',
          component: () => import('@/views/pages/Dashboard.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/overview',
          name: 'overview',
          component: () => import('@/views/pages/OverView.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/favorite',
          name: 'favorite',
          component: () => import('@/views/pages/Favorite.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/scan',
          name: 'scan',
          component: () => import('@/views/pages/Scan.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/keyword',
          name: 'keyword',
          component: () => import('@/views/pages/Keyword.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/diagnosis',
          name: 'diagnosis',
          component: () => import('@/views/pages/Diagnosis.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/notsupport',
          name: 'notsupport',
          component: () => import('@/views/pages/NotSupport.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/setting',
          name: 'setting',
          component: () => import('@/views/pages/Setting.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/usersetting',
          name: 'usersetting',
          component: () => import('@/views/pages/UserSetting.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/keyword/find',
          name: 'findkeyword',
          component: () => import('@/views/pages/keyword/Find.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/keyword/my',
          name: 'mykeyword',
          component: () => import('@/views/pages/keyword/MyKeyword.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/store/analysis',
          name: 'analysis',
          component: () => import('@/views/pages/store/ProductAnalysis.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/top/analysis',
          name: 'analysis',
          component: () => import('@/views/pages/topExposure/TopExposureAnalysis.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/target',
          name: 'target',
          component: () => import('@/views/pages/Target.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/price',
          name: 'price',
          component: () => import('@/views/pages/Price.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/info',
          name: 'info',
          component: () => import('@/views/pages/Info.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/syssetting',
          name: 'syssetting',
          component: () => import('@/views/pages/SysSetting.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/targethistory',
          name: 'targethistory',
          component: () => import('@/views/pages/TargetHistory.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/advertise',
          name: 'advertise',
          component: () => import('@/views/pages/Advertise.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/friday',
          name: 'friday',
          component: () => import('@/views/pages/Friday.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/friday2',
          name: 'frida2',
          component: () => import('@/views/pages/keyword/Friday2.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/mfriday',
          name: 'mfriday',
          component: () => import('@/views/pages/MobileFriday.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: true
          }
        },
        {
          path: '/pages/fridaysearch',
          name: 'fridaysearch',
          component: () => import('@/views/pages/FridaySearch.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor',
            requiresAuth: false
          }
        },
        {
          path: '*',
          redirect: '/pages/error-404'
        }
      ]
    }
    // Redirect to 404 page, if no match found
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    if (
      to.path === "/pages/login" ||
      to.path === "/pages/register"

    ) {
      if ((auth.isAuthenticated() || firebaseCurrentUser)) {
        router.push({ path: '/pages/home' })
      }
    }

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.requiresAuth) {
      if (!firebaseCurrentUser) {
        router.push({ path: '/pages/login?except=t' })
      }
      else {
        return next()
      }
    }
    else {
      return next()
    }


    // Specify the current path as the customState parameter, meaning it
    // will be returned to the application after auth
    // auth.login({ target: to.path });

  })

})

export default router
