


import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'


const errorCodeSet = [
  { no: "E-001", code: 'auth/claims-too-large', message: '구글에서 제공되는 페이로드가 허용크기 인 1000 바이트를 초과합니다.' },
  { no: "E-002", code: 'auth/email-already-exists', message: '해당 이메일은 이미 기존 사용자가 사용 중입니다.' },
  { no: "E-003", code: 'auth/id-token-expired', message: '세션 토근이 만료 되었습니다' },
  { no: "E-004", code: 'auth/id-token-revoked', message: '세션 토근이 회수 되었습니다.' },
  { no: "E-005", code: 'auth/insufficient-permission', message: '해당 리소스에 접근할 권환이 없습니다.' },
  { no: "E-006", code: 'auth/internal-error', message: '요청을 처리하는 동안 인증 서버에서 예기치 않은 오류가 발생했습니다. ' },
  { no: "E-007", code: 'auth/invalid-argument', message: '인증 방법에 잘못된 인수가 언급되었습니다.' },
  { no: "E-008", code: 'auth/invalid-claims', message: '사용자 지정 클레임 속성이 잘못되었습니다.' },
  { no: "E-009", code: 'auth/invalid-continue-uri', message: '유효한 URL 문자열이어야합니다.' },
  { no: "E-010", code: 'auth/invalid-creation-time', message: '생성시간은 유효한 UTC 형식이어야 합니다.' },
  { no: "E-011", code: 'auth/invalid-credential', message: '해당 사용자는 인증 작업을 수행하는 데 사용할 수 없습니다. ' },
  { no: "E-012", code: 'auth/invalid-disabled-field', message: '비활성화 된 사용자 속성에 제공된 값이 잘못되었습니다.' },
  { no: "E-013", code: 'auth/invalid-display-name', message: '해당 사용자의 이름 값이 누락되었습니다. 사용자 이름은 필수 입니다.' },
  { no: "E-014", code: 'auth/invalid-dynamic-link-domain', message: '제공된 동적 링크 도메인이 현재 프로젝트에 승인되지 않았습니다.' },
  { no: "E-015", code: 'auth/invalid-email', message: '잘못된 이메일 주소 입니다.' },
  { no: "E-016", code: 'auth/invalid-email-verified', message: '이메일 확인 속성이 잘못 되었습니다.' },
  { no: "E-017", code: 'auth/invalid-hash-algorithm', message: '해시 알고리즘이 잘못되었습니다.' },
  { no: "E-018", code: 'auth/invalid-hash-block-size', message: '해시 블록 사이즈가 숫자가 아닙니다.' },
  { no: "E-019", code: 'auth/invalid-hash-derived-key-length', message: '해시 블록 사이즈가 잘못 되었습니다.' },
  { no: "E-020", code: 'auth/invalid-hash-key', message: '유효한 해시 키 버퍼가 아닙니다.' },
  { no: "E-021", code: 'auth/invalid-hash-memory-cost', message: '유효한 해시 메모리 코스트 숫자가 아닙니다.' },
  { no: "E-022", code: 'auth/invalid-hash-parallelization', message: '유효한 해시 병렬 사이즈가 아닙니다.' },
  { no: "E-023", code: 'auth/invalid-hash-rounds', message: '유효한 해시 회차가 아닙니다.' },
  { no: "E-024", code: 'auth/invalid-hash-salt-separator', message: '유효한 해시 알고리즘 구분자 입니다.' },
  { no: "E-025", code: 'auth/invalid-id-token', message: '제공된 ID 토큰은 유효한 Firebase ID 토큰이 아닙니다.' },
  { no: "E-026", code: 'auth/invalid-last-sign-in-time', message: '마지막 로그인 시간은 유효한 UTC 날짜 문자열이어야합니다.' },
  { no: "E-027", code: 'auth/invalid-page-token', message: '페이지 토근이 유효하지 않습니다.' },
  { no: "E-028", code: 'auth/invalid-password', message: '비밀번호 가 올바르지 않습니다.' },
  { no: "E-028", code: 'auth/wrong-password', message: '비밀번호 가 올바르지 않습니다.' },
  { no: "E-029", code: 'auth/invalid-password-hash', message: '암호 해시는 유효한 바이트 버퍼 여야합니다.' },
  { no: "E-030", code: 'auth/invalid-password-salt', message: '유효하지 않은 값입니다.' },
  { no: "E-031", code: 'auth/invalid-phone-number', message: '전화번호가 올바르지 않습니다.' },
  { no: "E-032", code: 'auth/invalid-photo-url', message: '프로필 이미지 URL 값이 잘못되었습니다.' },
  { no: "E-033", code: 'auth/invalid-provider-data', message: '유효한 개체 배열이 아닙니다.' },
  { no: "E-034", code: 'auth/invalid-provider-id', message: '유효한 공급자 식별 코드가 아닙니다.' },
  { no: "E-035", code: 'auth/invalid-session-cookie-duration', message: '세션 쿠키 기간은 5 분에서 2 주 사이의 유효한 밀리 초 단위 여야합니다.' },
  { no: "E-036", code: 'auth/invalid-uid', message: '제공된 uid는 최대 128 자의 비어 있지 않은 문자열이어야합니다.' },
  { no: "E-037", code: 'auth/invalid-user-import', message: '가져올 사용자 레코드가 잘못되었습니다.' },
  { no: "E-038", code: 'auth/maximum-user-count-exceeded', message: '가져올 수있는 최대 사용자 수를 초과했습니다.' },
  { no: "E-039", code: 'auth/missing-android-pkg-name', message: 'Android 앱을 설치해야하는 경우 Android 패키지 이름을 제공해야합니다.' },
  { no: "E-040", code: 'auth/missing-continue-uri', message: '유효한 URL이 아닙니다.' },
  { no: "E-041", code: 'auth/missing-hash-algorithm', message: '암호 해시 알고리즘이 누락되었습니다.' },
  { no: "E-042", code: 'auth/missing-ios-bundle-id', message: '요청에 iOS 번들 ID가 없습니다.' },
  { no: "E-043", code: 'auth/missing-uid', message: '현재 작업에는 uid 식별자가 필요합니다.' },
  { no: "E-044", code: 'auth/operation-not-allowed', message: '제공된 로그인 제공 업체가 Firebase 프로젝트에서 사용 중지되었습니다.' },
  { no: "E-045", code: 'auth/project-not-found', message: '프로젝트를 찾을수 없습니다.' },
  { no: "E-046", code: 'auth/reserved-claims', message: '사용자 클레임이 예약되어 있습니다. ' },
  { no: "E-047", code: 'auth/session-cookie-expired', message: '세션 쿠키가 만료되었습니다.' },
  { no: "E-048", code: 'auth/session-cookie-revoked', message: '세션 쿠키가 회수되었습니다.' },
  { no: "E-049", code: 'auth/uid-already-exists', message: '제공된 uid는 이미 기존 사용자가 사용 중입니다. 각 사용자는 고유 한 uid를 가져야합니다.' },
  { no: "E-050", code: 'auth/unauthorized-continue-uri', message: '도메인이 허용 목록에 없습니다.' },
  { no: "E-051", code: 'auth/user-not-found', message: '사용자를 찾지 못했습니다.' },
  { no: "E-052", code: 'auth/too-many-requests', message: '로그인 시도가 여러 번 실패하여이 계정에 대한 액세스가 일시적으로 비활성화되었습니다. 비밀번호를 재설정하여 즉시 복원하거나 나중에 다시 시도 할 수 있습니다.' },
  { no: "E-053", code: 'auth/email-already-in-use', message: '해당 이메일은 이미 기존 사용자가 사용 중입니다.' },
  { no: "E-054", code: 'auth/weak-password', message: '패스워드는 6자리 이상이여야 합니다.' },


]

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function getCurrentDuedate(_timestamp) {
  var duedate = new Date(_timestamp);
  var today = new Date();
  var remainingDays = dateDiffInDays(today, duedate);
  return remainingDays;
}

function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function getCurrentTime() {
  var t = "";

  var t1 = new Date();

  var yyyy = t1.getFullYear().toString();
  var mm = (t1.getMonth() + 1).toString();
  var dd = t1.getDate().toString();
  var hh = t1.getHours() < 10 ? "0" + t1.getHours() : t1.getHours();
  var min = t1.getMinutes() < 10 ? "0" + t1.getMinutes() : t1.getMinutes();
  var ss = t1.getSeconds() < 10 ? "0" + t1.getSeconds() : t1.getSeconds();

  t =
    yyyy +
    "/" +
    (mm[1] ? mm : "0" + mm[0]) +
    "/" +
    (dd[1] ? dd : "0" + dd[0]) +
    " " +
    hh +
    ":" +
    min +
    ":" +
    ss;

  return t;
}


export default {
  refreshCache({ dispatch }, result) {

    var sref = firebase.database().ref("user/");

    sref
      .orderByChild("email")
      // .equalTo('yunjeong1143@naver.com')
      .equalTo(result.user.email)
      .once("value", function(sdata) {
        sdata.forEach(function(sdatas) {
          var _dirty = sdatas.val().dirtyAt ? sdatas.val().dirtyAt : sdatas.val().createdAt;

          var _name = "미확인";
          var _phone = "00000000000";

          //오래된 사용자를 위해 후처리 함.. 2019년도 이전 사용자는 해당 컬럼이 없었음.
          if (sdatas.val().userName) {
            _name = sdatas.val().userName;
          }
          else if (sdatas.val().tmp_name) {
            _name = sdatas.val().tmp_name;
          }

          if (sdatas.val().userPhone) {
            _phone = sdatas.val().userPhone;
          }
          else if (sdatas.val().tmp_phone) {
            _phone = sdatas.val().tmp_phone;
          }


          dispatch('updateUsername', {
            user: result.user,
            uid: result.user.uid,
            // uid: 'Z5A17ooJovactm9ub5RHdJa1gDn1',

            displayName: _name,
            companyName: sdatas.val().companyname ? sdatas.val().companyname : "",
            phone: _phone,
            isReloadRequired: result.isReloadRequired,

            key: sdatas.key,
            level: sdatas.val().maxlimit ? sdatas.val().maxlimit : 5,
            agency: sdatas.val().agency ? sdatas.val().agency : '-',
            remain: getCurrentDuedate(
              new Date(_dirty).addDays(
                parseInt(sdatas.val().expire)
              )
            ),
            expire: sdatas.val().expire,
            agency: sdatas.val().agency ? sdatas.val().agency : '-',
            limit: sdatas.val().maxlimit ? sdatas.val().maxlimit : 5,
            loggindttm: sdatas.val().loggindttm,
            isagency: sdatas.val().isAgencey ? sdatas.val().isAgencey : "F",
            ismd: sdatas.val().ismd ? sdatas.val().ismd : "F",
            createdAt: sdatas.val().createdAt,
            url: sdatas.val().url ? sdatas.val().url : "",
            includekeyword: sdatas.val().includekeyword ? sdatas.val().includekeyword : "F",
            alt1: sdatas.val().alt1 ? sdatas.val().alt1 : "",
            alt2: sdatas.val().alt2 ? sdatas.val().alt2 : "",
            isMaster: sdatas.val().isMaster ? sdatas.val().isMaster : "F",
            isanalytics: sdatas.val().isanalytics ? sdatas.val().isanalytics : "F"

          })
        });
      });
  },
  loginAttempt({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    // If remember_me is enabled change firebase Persistence


    // Change firebase Persistence
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function() {
        dispatch('login', newPayload)
      })
      .catch(function(err) {

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

  },
  login({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {

      if (payload.updateUsername) {

        var d2 = new Date();
        var d = getCurrentTime();


        dispatch('updateUsername', {
          user: payload.user,
          uid: payload.uid,
          displayName: payload.userDetails.displayName,
          companyName: payload.userDetails.companyName,
          phone: payload.userDetails.phone,
          isReloadRequired: true,

          key: payload.key,
          level: 5,
          agency: '-',
          remain: getCurrentDuedate(
            new Date().addDays(7)
          ),
          expire: "7",
          limit: 5,
          loggindttm: d,
          deposit: 0,
          isagency: "F",
          ismd: "F",
          agency: '-',
          createdAt: d2.getTime(),
          url: "",
          includekeyword: "F",
          alt1: "",
          alt2: "",
          isMaster: "F",
          isanalytics: "F"

        })
      }
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: '로그인 시도',
        text: '이미 로그인이 되어 있습니다.',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }

    // Try to sigin
    firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

      .then((result) => {
        result['isReloadRequired'] = true;
        dispatch('refreshCache', result)


        // // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        // // if username update is not required
        // // just reload the page to get fresh data
        // // set new user data in localstorage
        // if (!isUsernameUpdateRequired) {

        //   commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })

        // }
      }, (err) => {
        var _message = "";

        var _obj = errorCodeSet.find(item => err.code == item.code)
        if (_obj) {
          _message = "[" + _obj["no"] + "] " + _obj["message"];
        }
        else {
          _message = err.message;
        }

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: '에러',
          text: _message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },



  registerUser({ dispatch }, payload) {

    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(function(result) {
        payload.notify({
          title: '계정 생성',
          text: '성공적으로 생성되었습니다.',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        var d2 = new Date();
        var d = getCurrentTime();

        var newPostKey = firebase
          .database()
          .ref()
          .child("user/")
          .push().key;


        firebase
          .database()
          .ref("user/" + newPostKey)
          .set({
            init: d,
            uid: result.user.uid,
            createdAt: d2.getTime(),
            eventdttm: d,
            reverseCreatedAt: -d2.getTime(),
            dirtyAt: d2.getTime(),
            expire: "7",
            maxlimit: 5,
            companyname: payload.userDetails.companyName,
            email: result.user.email,
            loggindttm: d,
            userPhone: payload.userDetails.phone,
            userName: payload.userDetails.displayName,
            flow: payload.userDetails.flow
          })
          .then(() => {
            const newPayload = {
              user: result.user,
              uid: result.user.uid,
              userDetails: payload.userDetails,
              notify: payload.notify,
              updateUsername: true,
              key: newPostKey
            }
            dispatch('login', newPayload)

            router.push('/pages/completeregister')


          });
      }, (err) => {

        var _message = "";

        var _obj = errorCodeSet.find(item => err.code == item.code)
        if (_obj) {
          _message = "[" + _obj["no"] + "] " + _obj["message"];
        }
        else {
          _message = err.message;
        }

        payload.notify({
          title: '에러',
          text: _message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  updateUsername({ commit }, payload) {
    payload.user.updateProfile({
      uid: payload.uid,
      displayName: payload.displayName,
      companyName: payload.companyName,
      phone: payload.phone,
      key: payload.key,
      level: payload.level,
      agency: payload.agency,
      remain: payload.remain,
      expire: payload.expire,
      limit: payload.limit,
      loggindttm: payload.loggindttm,
      deposit: payload.deposit,
      isagency: payload.isagency,
      ismd: payload.ismd,
      createdAt: payload.createdAt,
      url: payload.url,
      includekeyword: payload.includekeyword,
      alt1: payload.alt1,
      alt2: payload.alt2,
      isMaster: payload.isMaster,
      isanalytics: payload.isanalytics

    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])

      newUserData.displayName = payload.displayName;
      newUserData.companyName = payload.companyName;
      newUserData.phone = payload.phone;
      newUserData.uid = payload.uid;
      newUserData.key = payload.key;
      newUserData.level = payload.level;
      newUserData.agency = payload.agency;
      newUserData.remain = payload.remain;
      newUserData.expire = payload.expire;
      newUserData.limit = payload.limit;
      newUserData.loggindttm = payload.loggindttm;
      newUserData.deposit = payload.deposit;
      newUserData.isagency = payload.isagency;
      newUserData.ismd = payload.ismd;
      newUserData.createdAt = payload.createdAt;
      newUserData.url = payload.url;
      newUserData.includekeyword = payload.includekeyword;
      newUserData.alt1 = payload.alt1;
      newUserData.alt2 = payload.alt2;
      newUserData.isMaster = payload.isMaster;
      newUserData.isanalytics = payload.isanalytics;


      commit('UPDATE_USER_INFO', newUserData, { root: true })

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        // location.reload(true)
        // router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: '에러',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  }
}
