

import Vue from 'vue'
import App from './App.vue'


Vue.config.silent = true
import "./registerServiceWorker";


import "core-js/stable";
import "regenerator-runtime/runtime";
// import '@babel/polyfill';

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

import VueNumber from './plugins/vue-number-animation'

Vue.use(VueNumber)

import 'ant-design-vue/dist/antd.css';
import { PageHeader, Button, Menu, Icon, Row, Col, Input, Divider, Checkbox, Dropdown, Select, Table, Modal, Radio, InputNumber, Tooltip, DatePicker, Upload, Switch, Tag, Tabs, Drawer, Collapse, Alert, Progress, Spin, Popconfirm, TimePicker } from 'ant-design-vue';
Vue.use(PageHeader)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Icon)
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Divider);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(Select);
Vue.use(Table);
Vue.use(Modal);
Vue.use(Radio);
Vue.use(InputNumber);
Vue.use(Tooltip);
Vue.use(DatePicker);
Vue.use(Upload);
Vue.use(Switch);
Vue.use(Tag);
Vue.use(Tabs)
Vue.use(Drawer)
Vue.use(Collapse)
Vue.use(Alert);
Vue.use(Progress);
Vue.use(Spin);
Vue.use(Popconfirm)
Vue.use(TimePicker)
// axios
import axios from './axios.js'
Vue.prototype.$http = axios
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$success = Modal.success;

// API Calls
import './http/requests'


// Firebase
import '@/firebase/firebaseConfig'


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
Vue.use(VueSlickCarousel);
// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.prototype.$cookie = VueCookies

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);


import ApexCharts from "apexcharts";
import VueApexCharts from "vue-apexcharts";

Vue.component("apexchart", VueApexCharts);




// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)



// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)

import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import "vue-lazy-youtube-video/dist/style.simplified.css";


Vue.config.productionTip = false
'use strict';
let app = null;

import firebase from "firebase";
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app =
      new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
      }).$mount('#app')
  }
});